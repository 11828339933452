type UpdatedMetaEnv = Pick<
	ImportMetaEnv,
	| "VITE_SENTRY_DSN"
	| "VITE_TITILER_URL"
	| "VITE_PUBLIC_POSTHOG_KEY"
	| "VITE_SENTRY_ENVIRONMENT"
>;

const UpdatedEnvVariables: UpdatedMetaEnv = {
	VITE_TITILER_URL: "${VITE_TITILER_URL}",
	VITE_SENTRY_DSN: "${VITE_SENTRY_DSN}",
	VITE_SENTRY_ENVIRONMENT: "${VITE_SENTRY_ENVIRONMENT}",
	VITE_PUBLIC_POSTHOG_KEY: "${VITE_PUBLIC_POSTHOG_KEY}",
};

const GetEnvVariables = () => {
	const validValues: Record<string, string> = {};
	for (const [key, value] of Object.entries(UpdatedEnvVariables)) {
		if (!value.includes("${VITE_")) {
			validValues[key] = value;
		}
	}

	return { ...import.meta.env, ...validValues } as const;
};

export const ENV = GetEnvVariables();

export const TITILER_URL = ENV.VITE_TITILER_URL;
export const API_URL = import.meta.env.PROD
	? `${window.location.protocol}//${window.location.host}`
	: ENV.VITE_API_URL;

export const APP_URL = import.meta.env.PROD
	? `${window.location.protocol}//${window.location.host}`
	: ENV.VITE_APP_URL;
